import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BASE_URL } from 'constants/values';

const CANDIDATES = ['Candidates'];
const LEADS = [
  'Leads',
  'Leads-Count',
  'Leads-Details',
  'Existing-Client-Prospects',
  'Leads-Types',
  'Lead-Status',
  'Lead-Types',
  'Leads-Client',
  'Leads-as-Contact',
];
const CONSTANTS = ['Countries', 'Lead-Sources', 'Job-Specifications'];
export const apiSlice = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: () => ({}),
  tagTypes: [...CONSTANTS, ...CANDIDATES, ...LEADS],
});
