import { defineStyleConfig } from '@chakra-ui/styled-system';

export const Checkbox = defineStyleConfig({
  baseStyle: {
    control: {
      _checked: {
        bg: 'primary.800', // Background color when checked
        borderColor: 'primary.800', // Border color when checked
        color: 'white', // Checkmark color
        _hover: {
          bg: 'primary.600', // Background color on hover when checked
          borderColor: 'primary.600',
        },
      },
      _hover: {
        borderColor: 'primary.400', // Border color on hover when unchecked
      },
    },
  },
});
