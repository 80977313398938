import { useDisclosure } from '@chakra-ui/react';
import TrLoading from 'components/app/TrLoading';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import {
  useListCandidateFilterMutation,
  usePinListCandidateFilterMutation,
} from 'store/candidatefilter.slice';
import {
  getCandidate,
  listCandidates,
  useDeleteCandidatesMutation,
  useListCandidatesMutation,
  useToggleHideCandidatesMutation,
} from 'store/candidates.slice';
import {
  CandidateData,
  candidateDataGroup,
  candidateGroups,
  swalContent,
  rowData,
} from 'types';
import { AtsConfirm } from 'utils/swal';
import CandidatesTagModal from '../../../Modals/Tag';
import CandidateTR from '../CandidateTR';
import LoadingPage from 'components/app/Loading';
import { useTRservices } from '../../useTRservices';
import DynamicCandidateTR from '../DynamicCandidateTR';
import { useFetchCandidateListQuery } from 'modules/Candidates/service/query';
import CandidatesDrawer from 'components/app/Candidates/Drawer';

interface countInt {
  job_id: number;
  count: number;
  grouplist: candidateGroups[];
  update: boolean;
}
interface props {
  job_id: number;
  colCount: number;
  job_title: string;
  client_name: string;
  isCheck: number[];
  handleCheck: (e: any, job_id: number) => void;
  isCheckWithJob: any[];
  onOpen: (data: rowData) => void;
  associateToJob: (candidate: CandidateData) => void;
  editCandidate: (candidate: CandidateData) => void;
  updateCount: (data: countInt) => void;
  reload: boolean;
  setTotalCandidate: (count: number) => void;
  setActiveList: (data: rowData[]) => void;
  setFirstLoad: (value: boolean) => void;
  groupItem: candidateGroups;
  fetchGroup: any;
  groupList: candidateGroups[];
  columns?: string[];
  setJobId: (job_id: number) => void;
}

const ByJob = ({
  job_id,
  colCount,
  job_title,
  client_name,
  isCheck,
  handleCheck,
  isCheckWithJob,
  onOpen,
  associateToJob,
  editCandidate,
  updateCount,
  reload,
  setTotalCandidate,
  setActiveList,
  setFirstLoad,
  groupItem,
  fetchGroup,
  groupList,
  columns,
  setJobId,
}: props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    candidatePgBtn,
    candidatePage,
    reloadGroupings,
    candidateDataJobId,
    candidateGroups,
    reloadByJob,
    candidatesByGrp,
  } = useSelector((state: any) => state.candidates);
  const state = useSelector((state: any) => {
    return state.candidates;
  });
  const param = useParams();
  const [reqCandidates, resCandidates] = useListCandidatesMutation({
    fixedCacheKey: 'candidate-list',
  });

  const {
    reloadGroups,
    candidateList,
    setCandidateList,
    rowId,
    setRowId,
    candidateCount,
    setCandidateCount,
    groupings,
    setGroupings,
    // groupItem, //commenting this, passing the groupitem in this custom state retulting to inconsistent count
    // setGroupItem, //commenting this
  } = useTRservices();
  // const [candidateList, setCandidateList] = useState([]);
  const params = useParams();
  const stateTab: any = params.candidatesTab;
  const [reqDeleteCandidate, resDeleteCandidate] =
    useDeleteCandidatesMutation();

  const [reqFilterList] = useListCandidateFilterMutation();
  const [reqPinList] = usePinListCandidateFilterMutation();

  const [reqToggleHide, resToggleHide] = useToggleHideCandidatesMutation();
  const [candidateId, setCandidateId] = useState(0);
  const [initialLoad, setInitialLoad] = useState(true);
  const {
    isOpen: isDrawerOpen,
    onOpen: onDrawerOpen,
    onClose: onDrawerClose,
  } = useDisclosure();
  const [selectedRow, setSelectedRow] = useState(0);
  const abortFilterControllerRef = useRef<AbortController | null>(null);
  const fetchPin = async () => {
    if (abortFilterControllerRef.current) {
      abortFilterControllerRef.current.abort();
    }

    abortFilterControllerRef.current = new AbortController();
    await reqPinList({
      signal: abortFilterControllerRef.current.signal,
    });
  };

  useEffect(() => {
    setGroupings(candidateGroups);
    const groups = candidateGroups?.data || candidateGroups;
    try {
      const item = groups?.filter(
        (item: candidateGroups) => item.job_id == job_id
      );

      if (item[0]) {
        // setGroupItem(item[0]);
      }
    } catch (e) {
      console.warn({ e, candidateGroups });
    }
  }, [candidateGroups]);

  const populateData = async (data: any, update: boolean) => {
    const candidates = data?.data || [];
    // console.log({ data });
    if (data?.count !== 0) {
      setCandidateList(candidates);
      const find: candidateGroups = groupItem;
      const currentCandidate =
        (find?.candidateData as rowData[]) || ([] as rowData[]);

      let rowList: rowData[] = [];
      let unChartedCandidates: rowData[] = [];
      candidates.map((item: CandidateData, key: number) => {
        const lookup = currentCandidate.find(
          (cand: rowData) => cand.id === item.id
        );
        rowList.push({
          ...lookup,
          rowId: key + 1,
          id: item.id,
          job_id,
          first_name: item.first_name,
          last_name: item.last_name,
        });
      });

      find?.candidateData?.map((item: candidateDataGroup) => {
        const lookup = rowList.find((row: rowData) => row.id == item.id);

        if (!lookup) {
          unChartedCandidates.push(item as rowData);
        }
      });

      let combined = [...rowList, ...unChartedCandidates];

      let formatted: rowData[] = [];
      combined.map((item: rowData, key: number) => {
        formatted.push({
          ...item,
          rowId: key + 1,
        });
      });

      setRowId(rowList);

      let newCandidates: candidateDataGroup[] = [];
      find?.candidateData?.map((data: candidateDataGroup) => {
        const lookup = candidates.find(
          (item: candidateDataGroup) => item.id === data.id
        );
        if (lookup) {
          newCandidates.push(data);
        }
      });

      const updatedData = groupList?.map((job: any) =>
        job.job_id === job_id
          ? { ...job, candidateData: rowList, list: data, total: data?.count }
          : job
      );

      // console.log({ candidates, rowList, combined, updatedData });
      const countData = {
        job_id,
        count: data?.count,
        grouplist: updatedData,
        update,
      } as countInt;

      updateCount(countData);
      setCandidateCount(data?.count);
    } else {
      const newGroups = groupList.filter((item: any) => item.job_id != job_id);

      const countData = {
        job_id,
        count: data.count || 0,
        grouplist: newGroups,
        update,
      } as countInt;

      updateCount(countData);
      setCandidateCount(0);
    }
  };

  useEffect(() => {
    if (initialLoad) {
      populateData(groupItem.list, false);
    }
  }, [groupItem, initialLoad]);

  const abortControllerRef = useRef<AbortController | null>(null);

  const fetchDandidate = async () => {
    // console.log({ job_id, groupItem });
    const params = {
      ...candidatePgBtn,
      job_status: null,
      take: 25,
      job_id,
    };
    // console.log({ candidateGroups });

    setInitialLoad(false);

    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    abortControllerRef.current = new AbortController();

    await reqCandidates({
      data: params,
      signal: abortControllerRef.current.signal,
    });
  };

  useEffect(() => {
    if (reloadByJob.reload && Number(reloadByJob.job_id) === Number(job_id)) {
      // fetchPin();
      reloadGroups(); //updated total candidates & list
      fetchDandidate();
    }
  }, [reloadByJob]);

  useEffect(() => {
    if (resCandidates.isSuccess) {
      const data = resCandidates?.data?.data;
      populateData(data, true);
    }
  }, [resCandidates.isSuccess]);

  // COMMENTED GROUP ITEM SINCE IT REPEATS FETCH FUNCTION
  useEffect(() => {
    const fetch = async () => {
      reloadGroups();
      fetchPin();
    };
    if (param['*'].includes('candidates')) {
      if (reload || reloadGroupings) {
        fetch();
      }
    }
  }, [
    reload,
    reloadGroupings,
    //  groupItem
  ]);

  useEffect(() => {
    const fetch = async () => {
      fetchDandidate();
      const count = groupItem.total - 1;
      const countData = {
        job_id,
        count,
        grouplist: groupList,
        update: false,
      } as countInt;
      // console.log({ countData });
      // console.log('updateCount');
      updateCount(countData);
      setCandidateCount(count);
    };
    if (resDeleteCandidate.isSuccess || resToggleHide.isSuccess) {
      fetch();
    }
  }, [resToggleHide.isSuccess, resDeleteCandidate.isSuccess]);

  const handleClick = (candidate: any) => {
    // setActiveList(rowId);
    setJobId(job_id);
    setTotalCandidate(groupItem?.total);
    dispatch(
      getCandidate({
        candidateData: candidate,
        candidateDataJobId: job_id,
      })
    );
    // dispatch(
    //   listCandidates({
    //     candidatePgBtn: { ...candidatePgBtn },
    //   })
    // );

    const data = rowId.find((item: rowData) => item.id == candidate.id);
    onOpen(data);
    navigate(
      `/candidates/views/${candidatePage.currentPage}/${
        candidate.id
      }/about?jobId=${job_id}&total=${candidateList?.length}&IsGroup=1&rowId=${
        data?.rowId || 1
      }`
    );
  };

  const handleDeleteCandidate = async (candidate: CandidateData) => {
    const content: swalContent = {
      title: 'Are you sure?',
      text: 'This will delete the candidate data.',
      buttons: ['Cancel', true],
      icon: 'warning',
    };
    const confirm = await AtsConfirm(content);
    if (confirm) {
      await reqDeleteCandidate({ id: candidate.id });
      reqFilterList({});
      fetchPin();
      // reloadGroups();

      fetchDandidate();

      dispatch(getCandidate({ candidateData: {} }));
    }
  };

  const handleHideCandidate = async (candidate: CandidateData) => {
    const content: swalContent = {
      title: 'Are you sure?',
      text: 'This will hide the candidate data.',
      buttons: ['Cancel', true],
      icon: 'warning',
    };
    const confirm = await AtsConfirm(content);
    if (confirm) {
      await reqToggleHide({ id: candidate.id });
      reqFilterList({});
      fetchPin();
      // reloadGroups();

      dispatch(getCandidate({ candidateData: {} }));
    }
  };

  const {
    isOpen: isOpenTag,
    onOpen: onOpenTag,
    onClose: onCloseTag,
  } = useDisclosure();

  const statusChangeSuccessCb = async (e: any) => {
    // console.log('reloading');
    // fetchPin();
    fetchDandidate();
    reloadGroups();
  };

  const addTagClick = (id: any) => {
    setCandidateId(id);
    onOpenTag();
  };

  function handleNextData() {
    if (candidateList.length === selectedRow + 1) {
      //end of group list then move to next group 1st item
      //find present groupIndex
      const presentGrpIndex = groupItem.index;
      const nextGroup = groupList[presentGrpIndex + 1];
      const candidateList = candidatesByGrp[nextGroup?.job_id];
      if (Array.isArray(candidateList) && candidateList?.length) {
        const firstCandidate = candidateList[0];
        setSelectedRow(0);
        //since rowID in URL is starting from 0
        navigate(
          `/candidates/views/${candidatePage.currentPage}/${
            firstCandidate.id
          }/${stateTab}?jobId=${firstCandidate?.job_id}&total=${
            candidateList.length
          }&IsGroup=1&rowId=${1}`
        );
      }
      // const presentGroupIndex=
    } else {
      const nextRowId = selectedRow + 1;
      const nextData = candidateList[nextRowId];

      //since rowID in URL is starting from 0
      navigate(
        `/candidates/views/${candidatePage.currentPage}/${
          nextData.id
        }/${stateTab}?jobId=${nextData?.job_id}&total=${
          candidateList.length
        }&IsGroup=1&rowId=${nextRowId + 1}`
      );
      setSelectedRow(nextRowId);
    }
  }
  function handlePrevData() {
    if (!selectedRow) {
      //start of group list then move to prev group last item
      //find present groupIndex
      const presentGrpIndex = groupItem.index;
      const prevGroup = groupList[presentGrpIndex - 1];
      const candidateList = candidatesByGrp[prevGroup?.job_id];
      if (Array.isArray(candidateList) && candidateList?.length) {
        const lastCandidate = candidateList.at(-1);
        setSelectedRow(0);
        //since rowID in URL is starting from 0
        navigate(
          `/candidates/views/${candidatePage.currentPage}/${lastCandidate.id}/${stateTab}?jobId=${lastCandidate?.job_id}&total=${candidateList.length}&IsGroup=1&rowId=${candidateList.length}`
        );
      }
    } else {
      const prevRowId = selectedRow - 1;
      const nextData = candidateList[prevRowId];
      //since rowID in URL is starting from 0
      navigate(
        `/candidates/views/${candidatePage.currentPage}/${
          nextData.id
        }/${stateTab}?jobId=${nextData?.job_id}&total=${
          candidateList.length
        }&IsGroup=1&rowId=${prevRowId + 1}`
      );
      setSelectedRow(prevRowId);
    }
  }

  if (resToggleHide.isLoading || resDeleteCandidate.isLoading) {
    return <LoadingPage />;
  }

  return (
    <>
      {resCandidates.isLoading || groupItem?.loading ? (
        <TrLoading
          rows={groupItem.total || groupItem.total || 3}
          columns={colCount}
        />
      ) : (
        <>
          {candidateList.map((candidate: CandidateData, key: number) => (
            <DynamicCandidateTR
              candidate={candidate}
              handleCheck={handleCheck}
              isCheck={isCheck}
              handleClick={() => {
                handleClick(candidate);
                setSelectedRow(key);
                onDrawerOpen();
              }}
              associateToJob={associateToJob}
              addTagClick={(e: number) => addTagClick(e)}
              editCandidate={editCandidate}
              handleDeleteCandidate={(candidate) =>
                handleDeleteCandidate(candidate)
              }
              handleHideCandidate={(candidate) =>
                handleHideCandidate(candidate)
              }
              fromJob={false}
              isCheckWithJob={isCheckWithJob}
              fromGroupByJobs={true}
              key={`${candidate.id}-${key}`}
              jobTitle={job_title}
              clientName={client_name}
              jobID={job_id?.toString()}
              clientID={candidate.jobs[0]?.client?.id}
              columns={columns}
              statusChangeSuccessCb={(e: any) => statusChangeSuccessCb(e)}
              groupItem={groupItem}
            />
          ))}
        </>
      )}
      {isDrawerOpen && (
        <CandidatesDrawer
          isOpen={isDrawerOpen}
          onClose={() => {
            onDrawerClose();
          }}
          rowId={selectedRow}
          setRowId={setSelectedRow}
          totalCandidates={candidateList.length}
          handlePrevData={handlePrevData}
          handleNextData={handleNextData}
          isLoading={resCandidates.isLoading}
          candidates={candidateList}
        />
      )}
      {isOpenTag && (
        <CandidatesTagModal
          isOpen={isOpenTag}
          onClose={onCloseTag}
          id={candidateId}
          idList={isCheck}
          isBulkTag={false}
          job_id={job_id}
          onSuccess={fetchDandidate}
        />
      )}
    </>
  );
};

export default ByJob;
